import React, { useEffect, useRef } from "react";

import Link from "next/link";
import styled from "styled-components";

import { Button } from "@components/Button";
import { Container } from "@components/Container";
import { Main } from "@components/Layout";
import { Meta } from "@components/Meta";
import { bp } from "@styles/theme";
import { useLoctool } from "@bigfish/react-loctool";
import { useRouter } from "next/router";
import { Path } from "@utils/Path";

const NotFoundPage = () => {
    const redirectTimeout = useRef<number | null>(null);
    const router = useRouter();

    useEffect(() => {
        redirectTimeout.current = window.setTimeout(onRedirectTimeout, 3000);
        return () => {
            if (redirectTimeout.current) {
                window.clearTimeout(redirectTimeout.current);
            }
        };
    }, []);

    const onRedirectTimeout = () => {
        if (redirectTimeout.current) {
            window.clearTimeout(redirectTimeout.current);
        }
        router.push(Path.currentOffers);
    };

    const Intl = useLoctool();
    return (
        <>
            <Meta title={`404 - ${Intl.formatMessage({ id: "common.brandName" })}`} ogTitle={`404 - ${Intl.formatMessage({ id: "common.brandName" })}`} />
            <MainContent>
                <Container>
                    <Content>
                        <img src="/illustrations/404.svg" alt="" />
                        <H1>{Intl.formatMessage({ id: "pages.notFound.title" })}</H1>
                        <p>{Intl.formatMessage({ id: "pages.notFound.description" })}</p>
                        <Link href={Path.currentOffers} passHref>
                            <Button.Primary as="a">{Intl.formatMessage({ id: "pages.notFound.homeLink" })}</Button.Primary>
                        </Link>
                    </Content>
                </Container>
            </MainContent>
        </>
    );
};

export default NotFoundPage;

const MainContent = styled(Main)`
    padding: 40px 0;
    border-top: 1px dashed ${props => props.theme.color.line};
    border-bottom: 1px solid ${props => props.theme.color.line};
`;

const Content = styled.div`
    max-width: 650px;
    margin: 0 auto;
    text-align: center;

    img {
        max-width: 100%;
        margin-bottom: 20px;

        ${bp.medium} {
            margin-bottom: 40px;
        }
    }

    p {
        max-width: 500px;
        margin: 0 auto 20px;

        ${bp.large} {
            font-size: 18px;
            line-height: 30px;
        }
    }
`;

const H1 = styled.h1`
    margin-top: 0;
    margin-bottom: 25px;
    color: ${props => props.theme.color.grayD};
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;

    ${bp.large} {
        font-size: 30px;
        line-height: 40px;
    }
`;
